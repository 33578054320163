import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import './RoundCard.css';
import '../../styles/Text.css'

const RoundCard = ({ choice, isSelected, onClick }) => {
    return (
        <Card
            className={isSelected ? 'newinterview-card-round-selected' : 'newinterview-card-round'}
            onClick={() => onClick(choice.value)}
        >
            <CardMedia
                component="img"
                height="160"
                image={choice.img}
                sx={{
                    width: '70%',
                    height: '50%',
                    marginTop: '10px'
                }}
            />

            <CardContent>
            <Typography className='normal-text-bold-small'>{choice.name}</Typography>
            <Typography id='normal-text-mini' sx={{marginTop: '2%'}}>{choice.time}</Typography>
            <Typography id='normal-text-mini' sx={{marginTop: '1%'}}>{choice.des}</Typography>
            </CardContent>
        </Card>
    );
};

export default RoundCard;
