const getOverallScore = (feedback) => {
    if (!feedback?.content || !Array.isArray(feedback.content)) {
        console.warn("Invalid feedback format in getOverallScore");
        return 0;
    }

    const overallSection = feedback.content.find(section => 
        section.section === "Overall Performance"
    );
    
    if (overallSection) {
        const score = overallSection.score;
        return score === "n/a" ? 0 : parseFloat(score);
    } else {
        
        const scores = feedback.content.map(section => {
            const score = section.score;
            return score === "n/a" ? 0 : parseFloat(score);
        }).filter(score => !isNaN(score));

        if (scores.length === 0) return 0;

        const totalScore = scores.reduce((acc, curr) => acc + curr, 0);
        return parseFloat((totalScore / scores.length).toFixed(1));
    }
};

const getOverallScoreWithScores = (scores, feedbackSections) => {
    const overallScoreSectionIndex = feedbackSections.findIndex(section => section.title === "Overall Performance");
    const overallScoreSection = feedbackSections[overallScoreSectionIndex];

    if (overallScoreSection) {
        let score = scores[overallScoreSectionIndex];
        return score === "n/a" ? 0 : parseFloat(score);
    } else {
        scores = scores.map(value => (value === null || value === undefined || value === '' || isNaN(value)) ? 0 : parseFloat(value));
        const totalScore = scores.reduce((acc, curr) => acc + curr, 0);
        return parseFloat((totalScore / scores.length).toFixed(1));
    }
};

export { getOverallScore, getOverallScoreWithScores };
