import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './HistoryCard.css';
import '../../styles/Text.css'

const HistoryCard = ({ data, moreHistory }) => {
    const navigate = useNavigate();
    if (moreHistory) {
        return (
            <Card
                className={'dashboard-history-card'}
                onClick={() => {navigate('/history')}}
            >
                    <Box className="dashboard-centralize">
                        <Typography id='normal-text-bold-mini'>More History</Typography>
                    </Box>
            </Card>
        )
    }
    return (
        <Card
            className={'dashboard-history-card'}
        >
            <Box className="dashboard-centralize">
            <Typography id='normal-text-bold-mini'>{data.round}</Typography>
            <Typography id='normal-text-mini'>{data.ts}</Typography>
            <Typography id='normal-text-mini'>{data.score}/5</Typography>
            </Box>
        </Card>
    );
};

export default HistoryCard;
