import { useEffect, useState, React, useRef } from 'react';
import axios from 'axios';
import "./Feedback.css";
import { unAuthorizedNotice, badIdNotice } from '../../components/feedback/PostInterviewNotice';
import { Typography, Box, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { getRadarData } from "../../components/feedback/RadarChart"
import { TopBar } from '../../components/top_bar/TopBar';
import '../../styles/Text.css';
import '../../styles/Button.css';
import '../../styles/DotStepper.css'
import FlippableCard from '../../components/feedback/FlippableCard';
import SumScoreCard from '../../components/feedback/SumScoreCard';
import { useUser } from '@clerk/clerk-react';
import { ResponsiveRadar } from '@nivo/radar';
import { getOverallScore } from '../../functions/GetOverallScore';
import { getRadarKeys } from '../../functions/GetRadarKeys';
import posthog from 'posthog-js';

/*
/feedback/?round=prod-sense
/interview/?round=hr-round
/interview/?round=customized/?job=${jobId}
*/

const Feedback = () => {
    const [feedback, setFeedback] = useState('');
    const [errorNotice, setErrorNotice] = useState(false);
    const [candidateEmail, setCandidateEmail] = useState('');

    const [jobId, setJobId] = useState(null);
    const [round, setRound] = useState('');
    const [evaluationCriteria, setEvaluationCriteria] = useState([]);
    const [date, setDate] = useState('');
    const [chatHist, setChatHist] = useState([]);
    const [radarData, setRadarData] = useState([]);

    const [showDetails, setShowDetails] = useState(false);
    const [isChatCollapsed, setIsChatCollapsed] = useState(false);

    const { isSignedIn, user, isLoaded } = useUser();
    const [ jobTitle, setJobTitle] = useState('Product Manager');
    const detailsRef = useRef(null);
    const [ feedbackSections, setFeedbackSections ] = useState([]) 

    useEffect(() => {
        if (isSignedIn) {
            setCandidateEmail(user.primaryEmailAddress.emailAddress);
            posthog.identify(user.primaryEmailAddress.emailAddress);
        }
    }, [isSignedIn])

    useEffect(() => {
        if (candidateEmail) {
            fetchChatCompletion(); // setCandidateEmail is asynchronous
        }
    }, [candidateEmail]);

    useEffect(() => {
      posthog.capture('$pageview', {
          $current_url: window.location.href,
          $host: window.location.hostname,
          $pathname: window.location.pathname,
      });
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.has("job")) {
            setJobId(queryParams.get('job'));
            setFeedback("Interview Finished. Thank you!")
        }
    }, []);

    useEffect(() => {
        if (feedback && round) {
            setFeedbackSections(getRadarKeys(round, evaluationCriteria));
            const radarData = getRadarData(feedback, round, evaluationCriteria);
            setRadarData(radarData);
        }
    }, [feedback ,round]);

 


    const fetchChatCompletion = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const feedbackId = queryParams.get('id');
        if (!feedbackId) {
            setErrorNotice(true)
            setFeedback(badIdNotice);
        } else {
            const headers = {
                'Content-Type': 'application/json',
            }
            const url = process.env.REACT_APP_BACKEND_URL + '/api/userData/feedback';
            axios.post(url, {
                feedbackId: feedbackId,
                email: user.primaryEmailAddress.emailAddress,
                jobId: jobId,
            }, { headers })
                .then(response => {
                    if (!jobId) {
                        // no feedback provided for real interview
                        handleCompletionResponse(response)
                    }
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        setErrorNotice(true)
                        setFeedback(unAuthorizedNotice)
                    }
                    console.error(error);
                });
        }
    }

    const removeControlCharacters = (str) => {
        const controlCharsRegex = /[\u0000-\u001F\u007F-\u009F]/g;
        return str.replace(controlCharsRegex, '');
    };

    const handleCompletionResponse = (response) => {
        const data = response.data;
        const content = data.data.reply.content; // an array of string
        const chatHistString = data.data.reply.chat_history;
        const date = data.data.reply.date;
        setJobTitle(data.data.reply.jobTitle);
        setEvaluationCriteria(data.data.reply.evaluationCriteria);
        setRound(data.data.reply.round);
        setErrorNotice(false);
        setFeedback(content);
        try {
            const sanitizedChatHistString = removeControlCharacters(chatHistString);
            const parsedChatHist = JSON.parse(sanitizedChatHistString);
            setChatHist(parsedChatHist);
        } catch (e) {
            console.error("Failed to parse chat history:", e);
            setChatHist([]);
        }
        setDate(date);
    }

    const filterFeedbackSections = (min, max) => {
        if (!feedback?.content || !Array.isArray(feedback.content)) {
            return [];
        }
        
        return feedback.content.filter(section => {
            let score = section.score;
            if (score === "n/a") {
                score = 0;
            } else {
                score = parseFloat(score);
            }
            return !isNaN(score) && score >= min && score <= max;
        });
    };

    const handleChatToggle = () => {
        setIsChatCollapsed(!isChatCollapsed);
        posthog.capture('feedback_click_chat_history_toggle', { isCollapsed: isChatCollapsed });
    };

    const topSkills = feedback.content ? filterFeedbackSections(4, 5) : [];
    const criticalSkills = feedback.content ? filterFeedbackSections(0, 2) : [];
    const roomForImprovement = feedback.content ? filterFeedbackSections(2.1, 3.9) : [];

    const handleButtonClick = () => {
        setShowDetails(!showDetails);
        posthog.capture('feedback_click_detail_button', { isCollapsed: showDetails });
        if (!showDetails) {
            setTimeout(() => {
                detailsRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        }
    };

    return (
        <>
            <TopBar />
            <Box className="feedback-container">
                {
                    (jobId || errorNotice) &&
                    <Box className="feedback-err-msg">
                        {feedback}
                    </Box>
                }
                {
                    !jobId && feedback && !errorNotice &&
                    <Box>
                        <Box className="feedback-summary">
                            <Box className="feedback-radar-chart-container">
                                {<ResponsiveRadar data={radarData.data}
                                    keys={['score']}
                                    indexBy="criteria"
                                    {...radarData.options} />}
                            </Box>
                            <Box className="feedback-sum-score-card-container normal-text">
                                <SumScoreCard
                                    score={getOverallScore(feedback)}
                                    position={jobTitle}
                                    round={
                                        round === "hr-round" ? "HR Round" :
                                            round === "prod-sense" ? "Product Sense" :
                                                round === "analytical-execution"? "Analytical Execution" :
                                                    round === "leadership"? "Leadership" : 
                                                        round === "Quick Mock"? "Quick Mock" : ""
                                    }
                                    date={date}
                                    description={feedback.overview}
                                    onViewDetailsClick={handleButtonClick}
                                    showDetails={showDetails}
                                />
                            </Box>
                        </Box>

                        {showDetails && (
                            <Box className='feedback-details-section'>
                                <Box ref={detailsRef} className="feedback-detail-first-row">
                                    <Box className="feedback-group-section">
                                        {topSkills.length > 0 && (
                                            <Box>
                                                <Typography variant="h6" className="feedback-subtitle-text subtitle-text">Your Top Skills</Typography>
                                                <Box className="feedback-flipcard-box">
                                                    {topSkills.map((section, index) => (
                                                        <FlippableCard
                                                            key={index}
                                                            score={section.score}
                                                            title={section.section}
                                                            description={section.text}
                                                            improvement={section.improve}
                                                        />
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box className="feedback-group-section">
                                        {criticalSkills.length > 0 && (
                                            <Box>
                                                <Typography variant="h6" className="feedback-subtitle-text subtitle-text">Critical Skills to Improve On</Typography>
                                                <Box className="feedback-flipcard-box">
                                                    {criticalSkills.map((section, index) => (
                                                        <FlippableCard
                                                            key={index}
                                                            score={section.score}
                                                            title={section.section}
                                                            description={section.text}
                                                            improvement={section.improve}
                                                        />
                                                    ))}
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box className="feedback-detail-second-row">
                                        <Box className="feedback-group-section">
                                            {roomForImprovement.length > 0 && (
                                                <Box>
                                                    <Typography variant="h6" className="feedback-subtitle-text subtitle-text">There's Room for Improvement On</Typography>
                                                    <Box className="feedback-flipcard-box">
                                                        {roomForImprovement.map((section, index) => (
                                                            <FlippableCard
                                                                key={index}
                                                                score={section.score}
                                                                title={section.section}
                                                                description={section.text}
                                                                improvement={section.improve}
                                                            />
                                                        ))}
                                                    </Box>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Box className="feedback-chat-history-section normal-text">
                            <Box className="feedback-subtitle-text feedback-chat-history-title-text subtitle-text">
                                Your Transcript
                                <FontAwesomeIcon
                                    icon={isChatCollapsed ? faAngleUp : faAngleDown}
                                    onClick={handleChatToggle}
                                    style={{ cursor: 'pointer', paddingLeft: '10px', alignSelf: 'center' }}
                                />
                            </Box>
                            <Box className={"feedback-chat-history " + isChatCollapsed? "feedback-collapsed":""} >
                                <Table className={'feedback-chat-history-table normal-text-small' + isChatCollapsed? "feedback-collapsed":""}>
                                    <TableBody>
                                        {Array.isArray(chatHist) ? (isChatCollapsed ? chatHist : chatHist.slice(0, 2)).map((message, index) => (
                                            <TableRow className="feedback-chathist-table-row">
                                                <TableCell className="feedback-chathist-table-cell">
                                                    <strong className={message.role === "user" ? "feedback-chathist-color" : ""}>
                                                        {message.role === "assistant" ? "Assistant" : "User"}:
                                                    </strong> {message.content}
                                                </TableCell>
                                            </TableRow>
                                        )) : null}
                                        {!isChatCollapsed && chatHist.length > 2 && (
                                            <TableRow className="feedback-chathist-table-row">
                                                <TableCell className='normal-text-bold feedback-chathist-table-cell'>
                                                    ...
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                    </Box>
                }
            </Box>
        </>
    );
}

export default Feedback;