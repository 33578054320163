import React from 'react';
import { Typography, Box, TextField } from '@mui/material';
import { DropzoneArea } from "mui-file-dropzone";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import './RoundCard.css';
import '../../pages/create_interview/CreateInterview.css'
import '../../styles/Text.css'
import posthog from 'posthog-js';

const JobDes = ({jobDes, setJobDes, setResume, resumeRequired }) => {

    const SmallAttachFileIcon = () => (
        <AttachFileIcon sx={{ fontSize: 'medium' }} />
    );

    const CustomDropzoneText = () => (
        <>
          <Typography className='normal-text-bold-mini'>
            <span style={{ color: 'var(--color-primary-semidark)' }}>Click to upload</span>
            <span style={{ color: 'black' }}> or Drag and drop</span>
          </Typography>
          <Typography className="normal-text-mini-grey" sx={{fontSize: '0.8em'}}>
            PDF, DOC, DOCX (max. 3MB)
          </Typography>
        </>
    );

    return (
        <Box className='newinterview-centralize'>
            <Box className='newinterview-fullwidth'>
                <Typography className='normal-text-bold-mini' >
                    Upload Resume {resumeRequired && <span style={{ color: 'red' }}>*</span>}
                </Typography>
            </Box>
            <Box
                className='newinterview-fullwidth'
                marginTop={2}
            >
                <DropzoneArea 
                      acceptedFiles={['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                      maxFileSize={3000000}
                      filesLimit={1}
                      onChange={(fileObjs) => {
                        if (fileObjs.length > 0) {
                            posthog.capture('resume_upload_succeeded', {
                                fileName: fileObjs[0].name,
                                fileType: fileObjs[0].type,
                                fileSize: fileObjs[0].size,
                            });
                            setResume(fileObjs[0]);
                        } else {
                            setResume(null);
                        }
                    }}
                      onDropRejected={(files) => {
                        const message = files[0].size > 3000000 ? 
                            'File size exceeds 3MB' : 'Only PDF, DOC, and DOCX files are allowed';
                        console.log('Error:', message);
                        alert(`File rejected: ${files[0].name}. ${message}`);
                      }}
                      onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                      dropzoneText={<CustomDropzoneText />}
                      dropzoneClass="newinterview-dropzone"
                      dropzoneParagraphClass="newinterview-droptext"
                      Icon={SmallAttachFileIcon}
                      showFileNames={true}
                      showPreviews={true}
                      showPreviewsInDropzone={false}
                      useChipsForPreview
                      previewGridProps={{container: { spacing: 1, direction: 'row' }}}
                      previewChipProps={{sx: { root: { minWidth: 160, maxWidth: 210} } }}
                      previewText={<Typography className='normal-text-mini' sx={{marginTop: '2%'}}>Selected files</Typography>}
                />
            </Box>

            <Box
                className='newinterview-fullwidth'
                marginTop={2}
            >
                <Typography className='normal-text-bold-mini' >
                    Job Description
                </Typography>
            </Box>
            <Box
                className='newinterview-fullwidth'
                marginTop={2}
            >
                <TextField 
                    fullWidth 
                    className="textfield" 
                    value={jobDes}
                    multiline
                    minRows={4} 
                    placeholder="Copy paste the job description here"
                    onChange={(event) => {
                        setJobDes(event.target.value);
                      }}  
                />
            </Box>
        </Box>
    );
};

export default JobDes;
