export const Title = "You're invited to an interview"

export function getText(jobTitle, companyName) {
    return `Thank you for your interest in the ${jobTitle} position at ${companyName}! We would like to invite you to speak with a member of our team. We hope to learn more about your background and skills that would make you a good fit for the job.`;
}

export const WaitingContent = "Please wait for the mock interview to be ready"

export function RoundInfo(round){
    if(round === 'hr-round')
        return ["HR Round", "30 min"]
    if(round === 'prod-sense')
        return ["Product Sense", "45 min"]
    if(round === 'analytical-execution')
        return ["Analytical Execution", "45 min"]
    if(round === 'leadership')
        return ["Leadership", "45 min"]
    if(round === 'Quick Mock')
        return ["Quick Mock", "30 min"]
    return ["", ""]
}

export const languageMapping = {
    "bg": "Български",
    "ca": "Català",
    "zh": "中文（简体）",
    "zh-TW": "中文（繁體）",
    "zh-HK": "粤语",
    "cs": "Čeština",
    "da": "Dansk",
    "nl": "Nederlands",
    "nl-BE": "Nederlands (België)",
    "en": "English",
    "et": "Eesti",
    "fi": "Suomi",
    "fr": "Français",
    "fr-CA": "Français (Canada)",
    "de": "Deutsch",
    "de-CH": "Deutsch (Schweiz)",
    "el": "Ελληνικά",
    "hi": "हिन्दी",
    "hu": "Magyar",
    "id": "Bahasa Indonesia",
    "it": "Italiano",
    "ja": "日本語",
    "ko": "한국어",
    "lv": "Latviešu",
    "lt": "Lietuvių",
    "ms": "Bahasa Melayu",
    "no": "Norsk",
    "pl": "Polski",
    "pt": "Português",
    "pt-BR": "Português (Brasil)",
    "pt-PT": "Português (Portugal)",
    "ro": "Română",
    "ru": "Русский",
    "sk": "Slovenčina",
    "es": "Español",
    "es-419": "Español (Latinoamérica)",
    "sv": "Svenska",
    "sv-SE": "Svenska (Sverige)",
    "th": "ไทย",
    "tr": "Türkçe",
    "uk": "Українська",
    "vi": "Tiếng Việt"
};
