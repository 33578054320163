export const Steps = [
    'Select interview round',
    'Personalize your interview',
]

export const StepOptions = [
  {
    step: 0,
    choices: [
        { name: 'HR Round', time: "30 min", des: "Conducted by a recruiter to get to know the candidate\'s background and assess their communication skills.", img: '/icons/hr-round.svg', value: 'hr-round' },
        { name: 'Product Sense', time: "45 min", des: "Conducted by a hiring manager to evaluate the candidate's ability to understand user needs and demonstrate empathy. ", img: '/icons/product-sense.svg', value: 'prod-sense' },
        { name: 'Analytical Execution', time: "45 min", des: "Conducted by a hiring manager to evaluate the candidate's ability to understand product strategy and make data-driven decisions. ", img: '/icons/analyze.svg', value: 'analytical-execution' },
        { name: 'Leadership', time: "45 min", des: "Conducted by a hiring manager to evaluate the candidate's ability to understand behavioral situation and work with cross-functional teams. ", img: '/icons/leadership.svg', value: 'leadership' },
    ],
  },
  {
    step: 1,
    choices: [
        { text: 'Personize your interview'},
    ],
  }
]