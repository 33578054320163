import axios from "axios";

export async function FindUser(isAuthenticated, userEmail)  {

    if (isAuthenticated) {

        const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/auth/signup";
        try {
            let response = await axios.post(
                apiUrl, { email: userEmail }
            )
            if (response.data.registered){
                return true;
            } else {
                return false;
            }
            
        } catch(error) {
            console.error("Error finding user in database:", error);
        }
    } 

    return false;
}