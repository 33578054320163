import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FindUser } from "../auth/FindUser";
import { AppBar, IconButton, Avatar, ButtonGroup, Button, Stack, Box, Menu, MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { SignedIn, SignedOut, SignInButton, UserButton, useUser } from '@clerk/clerk-react'
import posthog from 'posthog-js'
import './TopBar.css';
import '../../styles/Text.css'

const DotIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
    </svg>
  )
}

const TopBar = () => {
    const [candidatePhoto, setCandidatePhoto] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const { isSignedIn, user, isLoaded } = useUser();
    const navigate = useNavigate();
    //TODO: Add env here
    const [redirect, setRedirect] = useState('/home');
    const [redirectInitialized, setRedirectInitialized] = useState(false);
    const currentPath = window.location.pathname + window.location.search;

    useEffect(() => {
      if (currentPath.startsWith('/interview') && redirect !== currentPath) {
        setRedirect(currentPath);
        setRedirectInitialized(true);
      } else {
        setRedirectInitialized(true);
      }
    }, [currentPath, redirect]);

    useEffect(() => {
      if (isLoaded&isSignedIn&&user!==undefined && redirectInitialized) {
        setCandidatePhoto(user.imageUrl)
        redirectIfNeeded(user.primaryEmailAddress.emailAddress);
        posthog.identify(user.email);
        posthog.capture('top_bar_user_switch_page', {
          is_free_trial: true
        })
      }
    }, [isSignedIn, redirect])


    const handleAvatarClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleLogOut = async(e) => {
      handleMenuClose();
      e.preventDefault();
      setCandidatePhoto('');
      posthog.reset();
    }

    const redirectIfNeeded = async(email) => {
      let userFound = await FindUser(isSignedIn, email);
      if(!userFound){
        navigate(`/survey?redirect=${encodeURIComponent(redirect)}`);
      } 
    }

    const handleMenuItemClick = (path) => {
      handleMenuClose();
      navigate(path);
    };
    
    return (
      <AppBar position="static" className="topBar-appBar">
          <Stack direction="row" alignItems="center" justifyContent='space-between'>
            <img src="/icons/Mirwork logo 320_132.png" width="120" height="50" alt="STK Logo" title="STK" />
            <Stack direction="row" alignItems="center" marginLeft='auto'>
              <ButtonGroup>
                <Button className="topBar-button small-grey-text" onClick={() => handleMenuItemClick("/interview")}>Interviews</Button>
                {/* <Button className="topBar-button small-grey-text" onClick={() => handleMenuItemClick("/pricing")}>Pricing</Button> */}
                <Button className="topBar-button small-grey-text" onClick={() =>
                  (window.location.href =
                    "https://www.linkedin.com/company/mirworkai/?viewAsMember=true")
                  }
                >Jobs </Button>
                <Button className="topBar-button small-grey-text" onClick={() =>
                  (window.location.href = "https://discord.gg/X4JGNujhxp")
                  }
                >Community</Button>
              </ButtonGroup>
              <SignedOut>
                <SignInButton />
              </SignedOut>
              <SignedIn>
                <UserButton>
                  <UserButton.MenuItems>
                    <UserButton.Link
                      label="home"
                      labelIcon={<DotIcon />}
                      href="/home"
                    />
                  </UserButton.MenuItems>
                  <UserButton.MenuItems>
                    <UserButton.Link
                      label="history"
                      labelIcon={<DotIcon />}
                      href="/history"
                    />
                  </UserButton.MenuItems>
                </UserButton>
              </SignedIn>
            </Stack>
          </Stack>
      </AppBar>
    );
};

export { TopBar };