import React, { useState, useEffect } from 'react';
import { Box, Typography,  Select, MenuItem, FormControl, InputLabel, Paper, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import { TopBar } from '../../components/top_bar/TopBar';
import { Radar } from '../../components/dashboard/RadarChart';
import { Line } from '../../components/dashboard/LineChart';
import { roundType, radarKeys } from '../../functions/GetRadarKeys';
import { getOverallScoreWithScores } from '../../functions/GetOverallScore';
import RecordCard from  '../../components/dashboard/RecordCard';
import HistoryCard from '../../components/dashboard/HistoryCard';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { MonthShort } from '../../components/dashboard/Const';
import { FindUser } from '../../components/auth/FindUser';
import { useNavigate } from "react-router-dom";
import { useUser } from '@clerk/clerk-react';
import axios from 'axios';
import './Dashboard.css';
import '../../styles/Text.css';
import posthog from 'posthog-js';

export default function Dashboard() {
  const [round, setRound] = useState(roundType[0]);
  const [lineData, setLineData] = useState([]);
  const [radarData, setRadarData] = useState(new Map(roundType.map((item, _) => ([item,[]]))));
  const [roundDate, setRoundDate] = useState(new Map(roundType.map((item, _) => ([item,[]]))));
  const { isSignedIn, user, isLoaded } = useUser();
  const rootUrl = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = useState(true);
  const [hasData, setHasData] = useState(true);
  const [interviewNumber,setInterviewNumber] = useState(0);
  const [history,setHisory] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

const Dropdown=()=> {

  const handleChange = (event) => {
    setRound(event.target.value);
  };

  useEffect(() => {
    posthog.capture('$pageview', {
      $current_url: window.location.href,
      $host: window.location.hostname,
      $pathname: window.location.pathname,
    });
  }, []);

  return (
    <FormControl variant="outlined" style={{ marginTop: '1rem' }}>
      <InputLabel id="demo-simple-select-outlined-label">Round</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={round}
        onChange={handleChange}
        label="Round"
        sx={{ minWidth: 120, maxHeight: 40 }}
      >
        <MenuItem value="hr-round">HR Round</MenuItem>
        <MenuItem value="prod-sense">Product Design</MenuItem>
        <MenuItem value="analytical-execution">Analytical Execution</MenuItem>
        <MenuItem value="leadership">Leadership</MenuItem>
      </Select>
    </FormControl>
  );
}

  useEffect(() => {
    const fetchUserData = async () => {  
      if (isLoaded) {
        if (user!==undefined) {
            if(isSignedIn){
              posthog.identify(user.primaryEmailAddress.emailAddress);
              const userDataResult = await axios.post(rootUrl + "/api/userData/history", { email: user.primaryEmailAddress.emailAddress });
              if (userDataResult.data.data.dates && userDataResult.data.data.dates.length>0){
                  let newSectionScoreData = new Map(roundType.map((item, _) => ([item,[]])))
                  let newRadarDate = new Map(roundType.map((item, _) => ([item,[]])))
                  let newHistoryData = []
                  let accInterviews = new Map(roundType.map((item, _) => ([item, Array(12).fill(0)])))
                  const filteredIndices = userDataResult.data.data.interviewRounds
                    .map((round, index) => ({ round, index }))
                    .filter(({ round }) => round !== "Quick Mock")
                    .map(({ index }) => index);
                  // setInterviewNumber(userDataResult.data.data.dates.length)
                  setInterviewNumber(filteredIndices.length);
                  // console.log("rounds is:", userDataResult.data.data.interviewRounds)
                  for (let i = filteredIndices.length - 1; i >= 0; i--) {
                    const index = filteredIndices[i];
                    const item = userDataResult.data.data.interviewRounds[index]
                      const historyValues = userDataResult.data.data.radarValues[index].map((value,i)=>(!isNaN(value)?value:'0'))
                      if (newHistoryData.length<5){
                        newHistoryData.push({
                          round: item,
                          ts: userDataResult.data.data.dates[index],
                          score: getOverallScoreWithScores(historyValues, radarKeys.get(userDataResult.data.data.interviewRounds[index])),
                          feedbackID: userDataResult.data.data.feedbackId[index],
                          overview: userDataResult.data.data.overviews[index],
                        })
                      }
                      const [month, date, year] = userDataResult.data.data.dates[index].split('/')
                      accInterviews.get(item)[parseInt(month, 10)-1]++
                      setLineData(generateLineData(accInterviews))
                      newSectionScoreData.get(item).push(userDataResult.data.data.radarValues[index])
                      newRadarDate.get(item).push(userDataResult.data.data.dates[index])
                  }
                let newRadarData= new Map(roundType.map((item, _) => ([item,[]])))
                roundType.forEach(
                  (currentRoundType, _) => {
                    const currentRadarKeys = radarKeys.get(currentRoundType)
                    const currentScoreData = newSectionScoreData.get(currentRoundType)
                    const currentRadarDate = newRadarDate.get(currentRoundType)
                    const roundRadarData = []
                    if (currentScoreData.length>0){
                      for (let i = 0; i < currentRadarKeys.length; i++){
                        let duplicateDate = 0
                        let lastDate = ""
                        let singleField={"section": currentRadarKeys[i]}
                        for (let j = 0; j < currentRadarDate.length; j++){
                          let currentDate = currentRadarDate[j]
                          if (currentDate === lastDate){
                            duplicateDate++
                          } else {
                            duplicateDate=0
                          }
                          if (duplicateDate>0) {
                            currentDate=currentDate+"-"+duplicateDate.toString()
                          }
                          singleField[currentDate]=currentScoreData[j][i]
                          lastDate = currentRadarDate[j]
                          currentRadarDate[j] = currentDate
                        }
                        roundRadarData.push(singleField)
                      }
                    }
                    newRadarData.set(currentRoundType, roundRadarData)
                  }
                )
                setHisory(newHistoryData)
                setRadarData(newRadarData)
                setRoundDate(newRadarDate)
              } else{
                setHasData(false)
              }
              setLoading(false);
            }
        }
      }

      setLoading(false);
    };
     
    fetchUserData();
  }, [isSignedIn, isLoaded]);



  const generateLineData = (accInterviews) => {
    const lineData = []
    for (let i = 0; i < roundType.length; i++){
        let singleRound={"id": roundType[i],data:[]};
        for (let j=0; j<MonthShort.length;j++){
            singleRound.data.push({"x":MonthShort[j] ,"y": accInterviews.get(roundType[i])[j]})
        }
        lineData.push(singleRound)
    }
    return lineData;
  };


  if (loading) {
    return <div>Loading...</div>; // Add a loading state to display while fetching data
  }

  const steps = [
    {label: "Interview Performance", content: (
        <>
            <Box className="dashboard-vertical-step-center-container">
                
                    <Box className="dashboard-horizon-container">
                      <Box className="dashboard-text-container">
                          <Typography className="normal-text" >
                              Interview Performance
                          </Typography>
                      </Box>
                      <Dropdown/>
                    </Box>
                    <Box className="dashboard-radar-container">
                        <Radar radarData={radarData.get(round)} radarKey={roundDate.get(round)} />
                    </Box>

            </Box>
        </>
    )},
    {label: "Interview Historty", content: (
        <>
            <Box className="dashboard-vertical-step-center-container">
                <Box className="dashboard-text-container">
                    <Typography className="normal-text">
                        Interview Hisroty
                    </Typography>
                </Box>
                <Box className="dashboard-line-container">
                    <Line data={lineData}/>
                </Box>
            </Box>
        </>
        
    )},
    
    
  ]

  const maxSteps = steps.length;
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const stepper = (
    <Box sx={{ marginLeft: '10px', marginRight: '10px', flexGrow: 1}}>
    <Box sx={{ height: 410, width: '100%' }}>
      {steps[activeStep].content}
    </Box>
    <MobileStepper
      variant="text"
      steps={maxSteps}
      position="static"
      activeStep={activeStep}
      nextButton={
        <Button
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          Next
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
  </Box>
  );

  const imgPlaceHolder = (
    <Box className="dashboard-nodata-box">
      <Box className="dashboard-nodata-image-box">
        <img
          src="/icons/empty-history.svg"
          alt="empty-history"
          className="dashboard-nodata-image"
        />
      </Box>
      
      <Typography className="test-flex">
        Let's Mock Interviews!
      </Typography>
    </Box>
  )

  return (
    <>
      <TopBar />
      <Box className="dashboard">
        <Box className="dashboard-upper-container">
                {/* Left box */}
                <Box className="dashboard-left-box">
                    <Typography className="subtitle-text subtitle-text-history">
                        Your Recent interviews
                    </Typography>

                    {/* Card component */}
                    <Box className="dashboard-left-card">
                        <Box className="dashboard-step-container">
                            {hasData?stepper:imgPlaceHolder}
                        </Box>
                    </Box>
                </Box>
                {/* Right side box */}
                <Box className="dashboard-right-box">
                    <Typography className="subtitle-text subtitle-text-history">
                        Your Record
                    </Typography>
                    <Box className="dashboard-right-card">
                        <RecordCard interviewNumber={interviewNumber}/>
                    </Box>
                </Box>
        </Box>
        <Box className="dashboard-upper-container">
            <Box className="dashboard-lower-box">
                <Box>
                    <Typography className="subtitle-text subtitle-text-history" >
                        History
                    </Typography>
                </Box>
                <Box className="dashboard-card-container-round">
                        {history.map((choice) => {
                            return (
                                <HistoryCard
                                    moreHistory={false}
                                    data={choice}
                                />
                            );})
                        }

                        <HistoryCard
                            moreHistory={true}
                            data={null}
                        />
                </Box>
            </Box>
        </Box>
      </Box>
    </>
  );
}