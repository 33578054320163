import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { gapi, loadAuth2 } from 'gapi-script'
import Swal from 'sweetalert2'
import { Avatar, Box } from '@mui/material';
import { createClient, LiveTranscriptionEvents, LiveTranscriptionEvent } from "@deepgram/sdk";
import { sessionExpireNotices } from "../helper/Const";
import "./speech-to-text.css";

/*
/interview/?round=prod-sense
/interview/?round=hr-round
/interview/?round=analytical-execution
/interview/?round=leadership
/interview/?round=customized/?job=${jobId}
round: required
*/

var promptJson;
var systemPrompt;
var initialQuestion;
var interviewQuestion;
var promptId;
const chatCompletionHist = [];
var dg_mic;
var dg_socket;
const dgFinalTranscriptHist = [];

const { v4: uuidv4 } = require('uuid');

// timeouts for detecting when the user finishes speaking
var timeOutSeconds = 2500;
var timeOutSecondsAfterJoining = 1000;
var sessionTimeOutSeconds = 30000;
var sessionAliveRetryTimes = 2;
var remainingRetryTimes = 2;
const sessionResumeText = "Interview paused. Please click the button to resume when you are ready."
var isPostingNotice = false;

// timeouts for detecting when the AI interviewer finishes speaking
let waitingTimeout;
var aiTimeOutSeconds = 500;
var interviewStartTime = -1.0;
let uuid;

const updatePrompt = (role, content) => {
  promptJson = {
      conversation: [
          ...promptJson.conversation, 
          {
          role: role,
          content: content,
      }]
  }
}


const SpeechToText = ({isMicOn, isccOn, resume, jd, candidate, round, language,
                       setIntvSpeaking, setCandSpeaking, setInterviewPrompt,quickMockJobId}) => {

    const [hasInitialQuestionFinished, setInitialQuestionFinished] = useState(false);
    const [isUttering, setIsUttering] = useState(false);
    const [isSocketOn, setSocketOn] = useState(false);
    const [isMicrophoneOn, setMicrophoneOn] = useState(false);
    const isMicrophoneOnRef = useRef(isMicrophoneOn);
    const [dgFinalTranscript, setDgFinalTranscript] = useState("");
    const [dgTempTranscript, setDgTempTranscript] = useState("");
    const [jobId, setJobId] = useState(null);
    const [QMJobId, setQMJobId] = useState(null);
    const [defaultInterview, setDefaultInterview] = useState(false);

    const wsRef = useRef(null);
    const sessionExpireNotice = sessionExpireNotices[language] || "Sorry I can't hear you. Could you repeat what you said?";

    useEffect(() => {
      const queryParams = window.location.search.slice(1); 
      const paramsArray = queryParams.split('&'); 
  
      const jobParam = paramsArray.find(param => param.startsWith('job='));

      if (jobParam) { 
        const jobIdValue = jobParam.split('=')[1]; 
        setJobId(jobIdValue);
      } else if (quickMockJobId){
        setQMJobId(quickMockJobId);
      } else {
        setDefaultInterview(true);
      }
  
      const getMic = async () => {
          dg_mic = await getMicrophone();
      }
      getMic();
      
  }, []);
  

  useEffect(() => {
    if (jobId !== null || QMJobId !== null ) {
      startInterview();
    } else if (defaultInterview) {
      startInterview();
    }
  }, [jobId, QMJobId, defaultInterview]);


  // useEffect(()=> {
  //   if (quickMockJobId !== undefined) {
  //     console.log("quickmockjobid is:", quickMockJobId);
  //     startInterview();
  //   }
  // }, [quickMockJobId]);

  useEffect(() => {
      setInterviewPrompt(promptId, interviewQuestion);
  }, [promptId, interviewQuestion])
    
    useEffect(() => {
        if(isUttering){
            const mediaSource = new MediaSource();
            let sourceBuffer;
            let audioChunks = [];
            let allDataReceived = false;
            let fullText = '';

            let audio = new Audio();
            let objectURL = URL.createObjectURL(mediaSource);
            audio.src = objectURL;

            mediaSource.addEventListener('sourceopen', () =>{
                if(!sourceBuffer){
                    sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
                    sourceBuffer.addEventListener('updateend', () => {
                        if (audioChunks.length > 0 && !sourceBuffer.updating) {
                            sourceBuffer.appendBuffer(audioChunks.shift());
                        }
                    });
                }
            })

            if (wsRef.current) {
                wsRef.current.onmessage = (event) => {
                    if (event.data instanceof Blob) {
                        const reader = new FileReader();
                        reader.onload = () => {
                            audioChunks.push(reader.result);
                            if (sourceBuffer && !sourceBuffer.updating) {
                                sourceBuffer.appendBuffer(audioChunks.shift());
                            }
                        };
                        reader.readAsArrayBuffer(event.data);
                    } else if (typeof event.data === 'string') {
                        if (event.data === "sentence finished") {
                            if (audioChunks.length > 0 && !sourceBuffer.updating) {
                                sourceBuffer.appendBuffer(audioChunks.shift());
                            }
                        } else {
                            // console.log("[INFO] all data received")
                            allDataReceived = true;
                            fullText = event.data;
                            if (!sourceBuffer.updating && audioChunks.length === 0) {
                                mediaSource.endOfStream();
                            }
                        }
                    }
                };
            }

            audio.addEventListener('play', () => {
                setIntvSpeaking(true);
                console.log("[INFO] Interviewer speaking")
                clearTimeout(waitingTimeout);
            });

            audio.addEventListener('ended', () =>{
                console.log("[INFO] Interviewer got all the data for speaking")
            });

            // audio.addEventListener('error', (e) => {
            //     console.error('Error playing audio:', e);
            // });

            audio.addEventListener('waiting', () => {
                clearTimeout(waitingTimeout);
                waitingTimeout = setTimeout(() => {
                    audio.dispatchEvent(new Event('ended'));
                }, aiTimeOutSeconds);
            });

            audio.onended = () => {
                if (allDataReceived && mediaSource.readyState === 'open') {
                    mediaSource.endOfStream();
                }
                if(allDataReceived){
                    setIntvSpeaking(false); // turn of indicator
                    utterOnEnd(fullText);
                }
            };

            audio.play().catch(error => console.error("Error playing audio:", error));

            return () => {
                if (wsRef.current) {
                    wsRef.current.onmessage = null;
                }
                if (mediaSource.readyState === 'open') {
                    mediaSource.endOfStream();
                }
                audio.pause();
                audio.src = '';
                URL.revokeObjectURL(objectURL);
            };   
        } 
    }, [isUttering])

    useEffect(() => {
      const canOpenMicrophone = isMicOn && isSocketOn && !isUttering;
    
      if (!isMicrophoneOn && canOpenMicrophone) {
        if(dg_mic && dg_mic.state !== 'recording'){
          // truly turn on microphone
          openMicrophone();
        }
        // set microphone on
        setMicrophoneOn(true);
          
      } else {
        if (isMicrophoneOn && (!isSocketOn || isUttering)) {
          // truly turn off mic
          closeMicrophone();
        }
        if (isMicrophoneOn && !canOpenMicrophone) {
          // set mic off
          setMicrophoneOn(false);
          setCandSpeaking(false);
        }
        if (!isMicrophoneOn && dg_mic && dg_mic.state == 'recording'){
          closeMicrophone();
          setMicrophoneOn(false);
          setCandSpeaking(false);
        }
      }
    }, [isMicOn, isUttering, isSocketOn]);

    useEffect(() => {
      isMicrophoneOnRef.current = isMicrophoneOn;
    }, [isMicrophoneOn]);


    const startInterview = () => {
      if(!wsRef.current){
          uuid = uuidv4();
          console.log('ws address: ', process.env.REACT_APP_WS_ADDRESS);
          const wsUrl = new URL(process.env.REACT_APP_WS_ADDRESS);
          wsUrl.searchParams.append('session-id', uuid);
          wsRef.current = new WebSocket(wsUrl.toString());
      }
      setIsUttering(true);
      const fetchPromptUrl = process.env.REACT_APP_BACKEND_URL + "/api/userData/interviewPrompt";
      const dataToSend = {
        round: round,
      };
      if (jobId) {
        dataToSend.jobId = jobId;
      }
      if (QMJobId) {
        dataToSend.quickMockJobId = QMJobId;
      }
      axios.post(fetchPromptUrl, dataToSend)
      .then(response => {
          initialQuestion = response.data.initialQuestion;
          systemPrompt = response.data.systemPrompt;
          promptId = response.data.promptId;  
          if (round === 'hr-round'){
              systemPrompt = `${systemPrompt}\n\`\`\`\n${resume}\n\`\`\``;
              if (jd){
                  systemPrompt += `\n\n Here's the job description \n\`\`\`\n${jd}\n\`\`\``
              }
          }
          if (['prod-sense', 'analytical-execution', 'leadership'].includes(round)){
            interviewQuestion = response.data.interviewQuestion;
          }
          console.log("[INFO] Initial question is:", initialQuestion);
          promptJson = {
              conversation: [{
                  role: 'system',
                  content: systemPrompt
              },{
                  role: 'assistant',
                  content: initialQuestion
              }
          ]}
          interviewStartTime = new Date().getTime();
          setTimeout(() => {
              fetchChatCompletion(true, false);
          }, timeOutSecondsAfterJoining);
      })
      .catch(error => {
          console.error("Error fetching prompt:", error);
      });
    }

    const handleNewUserInput = (userInput) => {
        updatePrompt('user', userInput);
        fetchChatCompletion(false, true);
    }

    const fetchChatCompletion = (isInitialQuestion, textGen) => {
        const dataToSend = {
            conversation: promptJson.conversation,
            isInitialQuestionTag: isInitialQuestion,
            textGen: textGen, // if need to the generate the conversation
            clientuuid: uuid,
            language: language,
        };
        const stringfyData = JSON.stringify(dataToSend, null, 2);
        const headers = {
            'Content-Type': 'application/json',
        }
        const url = process.env.REACT_APP_BACKEND_URL + '/api/openai/chatCompletion';
        console.log('[INFO] Fetching chat completion');
        axios.post(url, stringfyData, { headers })
        .then(response => {
            console.log("[INFO] Fetch chat completion finishes");
        })
        .catch(error => {
            // Handle any errors
            console.error(error);
        });
    }

    const utterOnEnd = async (fullText) => {

        if(hasInitialQuestionFinished){
            chatCompletionHist.push(fullText);
            if (!isPostingNotice){
              updatePrompt('assistant', fullText);
            } else {
              isPostingNotice = false;
            }
        } else {
            setInitialQuestionFinished(true);
        }

        setIsUttering(false);
        await turnOnSocket();
    }

    // deepgram
    const getTempApiKey = async() => {
      const result = await fetch(process.env.REACT_APP_BACKEND_URL + "/api/dgKey");
      const json = await result.json();
      return json.key;
    }

    const getMicrophone = async() => {
      const userMedia = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      return new MediaRecorder(userMedia);
    }

    const openMicrophone = async() => {

      await dg_mic.start(0);
      dg_mic.ondataavailable = (e) => {
        if (isMicrophoneOnRef.current) {
          const data = e.data;
          dg_socket.send(data);
        }
      }
    };

    const closeMicrophone = async() => {
      if(dg_mic) dg_mic.stop();
    }

    const turnOnSocket = async () => {

      const key = await getTempApiKey();

      const dg_client = createClient(key);
      
      dg_socket = dg_client.listen.live({ model: "nova-2",
                                          smart_format: true,
                                          interim_results: true,
                                          utterance_end_ms: timeOutSeconds,
                                          language: language,
                                          // vad_events: true,
                                          // endpointing: 30000,
                                        });

      // const dg_interval = setInterval(() => {
      //   console.log("[DG] Sending KeepAlive")
      //   dg_socket.send(JSON.stringify(`{ "type": "KeepAlive" }`));
      // }, socketTimeOutSeconds);
      
      let userSpeakingOnEndCalled = false;

      dg_socket.on(LiveTranscriptionEvents.Open, () => {
        console.log("[DG] connected to websocket");
        let concatenated = "";
        var transcript = "";
        setSocketOn(true);
        
        const dg_finish_interval = setInterval(() => {
          closeMicrophone();
          
          if (remainingRetryTimes == 0){
            showReconnectionPopUp()
            remainingRetryTimes = sessionAliveRetryTimes;
          } else {
            askUserToRepeat()
          }
          clearInterval(dg_finish_interval);
        }, sessionTimeOutSeconds)
        
        dg_socket.on(LiveTranscriptionEvents.Transcript, (data) => {

            transcript = data.channel.alternatives[0].transcript;

            if (transcript !== "") {
              clearInterval(dg_finish_interval);
              remainingRetryTimes = sessionAliveRetryTimes;

              if (!data.is_final){
                setCandSpeaking(true);
                console.log("[DG] interim:", transcript);
                setDgTempTranscript(transcript);
              }
              else{
                concatenated = concatenated + " " + transcript;
                console.log("[DG] final:", concatenated);
                setDgFinalTranscript(concatenated);
                setDgTempTranscript("");
              }
            }
        });

        dg_socket.on(LiveTranscriptionEvents.UtteranceEnd, (data) => {
          console.log("[DG] full:", concatenated);
          // userSpeakingOnEnd(concatenated);
          if (!userSpeakingOnEndCalled) {
            userSpeakingOnEndCalled = true;
            userSpeakingOnEnd(concatenated);
          }
          concatenated = "";
          setCandSpeaking(false);
        })

        dg_socket.on(LiveTranscriptionEvents.Error, (e) => console.error(e));

        dg_socket.on(LiveTranscriptionEvents.Close, (e) => {
          // console.log("[DG] closed", e.reason);
          // if(concatenated || transcript){
          //   // if the socket closes before UtteranceEnd
          //   userSpeakingOnEnd(concatenated? concatenated : transcript);
          //   concatenated = "";
          //   transcript = "";
          // }
          if (!userSpeakingOnEndCalled && (concatenated || transcript)) {
            userSpeakingOnEndCalled = true;
            userSpeakingOnEnd(concatenated || transcript);
            concatenated = "";
            transcript = "";
          }
          setSocketOn(false);
          setCandSpeaking(false);
        });
      });
    }

    const askUserToRepeat = () => {
      updatePrompt('user', "");
      dgFinalTranscriptHist.push("");
      updatePrompt('assistant', sessionExpireNotice);
      fetchChatCompletion(false, false);
      isPostingNotice = true;
      setIsUttering(true);
      remainingRetryTimes -= 1;
    }
    
    const showReconnectionPopUp = () => {
      Swal.fire({
        title: `<div class="normal-text-mini">`+sessionResumeText,
        // text: "You won't be able to revert this!",
        confirmButtonText: "Resume",
        confirmButtonColor: "var(--color-primary-light)",
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          turnOnSocket();
        }
      });
    }

    const userSpeakingOnEnd = (concatenated) => {
      dgFinalTranscriptHist.push(concatenated);
      handleNewUserInput(concatenated);
      setIsUttering(true);
      dg_socket.finish();
      setDgFinalTranscript("");
      setDgTempTranscript("");
    }


    return (
      <Box className="mainScreen-content">   
      {
        isccOn &&
        <Box className="stt-chatHistory-container">
          <Box className="stt-chatHistory-content">
            <div className="stt-flex-content">
              <Avatar className="stt-intv-avatar">INTV</Avatar>
              <p className='normal-text stt-p'>
                {hasInitialQuestionFinished?  `Interviewer: ${initialQuestion}` : `Interviewer: ...`}
              </p>
            </div>
              {
                dgFinalTranscriptHist.map((item, index) => (
                  <div key={index}>
                    {item && 
                      <div className="stt-flex-content">
                        <p className="normal-text stt-p stt-candidate-content"> 
                          {item && 
                          `${candidate}` + `:` +  ` ${item} `}
                        </p>
                        <Avatar className="stt-cand-avatar">CAND</Avatar>
                      </div>
                    }
                    <div className="stt-flex-content">
                      <Avatar className="stt-intv-avatar">INTV</Avatar>
                        <p className="normal-text stt-p stt-interviewer-content">
                          {`Interviewer: ${chatCompletionHist.length > index ? chatCompletionHist[index] : " ..."}`}
                        </p>
                    </div>
                  </div>
                ))
              }
              {
                (dgTempTranscript.length > 0 || dgFinalTranscript.length > 0) && 
                (chatCompletionHist.length >= dgFinalTranscriptHist.length) &&
                //if the candidate is speaking and if the MIR has responded the previous round of conversation
                <div>
                  <div className="stt-flex-content">
                    <p className="normal-text stt-p stt-candidate-content">{candidate}: {dgFinalTranscript + '\n' + dgTempTranscript}</p>
                    <Avatar className="stt-cand-avatar">CAND</Avatar>
                  </div>
                </div>
              }
          </Box>
        </Box>
      }
      </Box>
    );
}

export function getChatHist(){
    return promptJson.conversation.slice(1); // to exclude the system prompt
}
export default SpeechToText;