export const Steps = [
    "About yourself",
    'What\'s your goal?',
    'What\'s your timeline?',
    "What industries are you interested in?",
    "What companies are you interested in?",
    "What type of roles are you interested in?",
]

export const StepOptions = [
  { step: 0, choices: [] },
  {
    step: 1,
    choices: [
        { text: 'Finding my first job', img: '/icons/find-first-job.svg', value: 'find_first_job' },
        { text: 'Finding a new job', img: '/icons/new-job.svg', value: 'find_new_job' },
        { text: 'Learning the interview process', img: '/icons/learning.svg', value: 'learn_process' },
    ],
  },
  {
    step: 2,
    choices: [
        { text: 'As soon as possible', img: '/icons/as-soon-as.svg', value: 'asap' },
        { text: '3 to 6 months', img: '/icons/3-6-01.svg', value: 'three_to_six_months' },
        { text: 'More than 6 months', img: '/icons/6more.svg', value: 'more_than_six_months' },
    ],
  },
  {
    step: 3,
    choices: [
        { text: 'Technology', value: 'technology' },
        { text: 'Healthcare', value: 'healthcare' },
        { text: 'Finance', value: 'finance' },
        { text: 'Education', value: 'education' },
        { text: "I'm not sure yet", value: 'uncertain' },
    ],
  },
  {
    step: 4,
    choices: [
        { text: 'MAANG (Meta, Apple, Amazon, Netflix, Google)', value: 'maang' },
        { text: 'Fortune 500', value: 'fortune_500' },
        { text: 'Mid-size companies', value: 'midsize' },
        { text: 'Startup', value: 'startup' },
        { text: 'All of the above', value: 'all' },
    ],
  },
  {
    step: 5,
    choices: [
        { text: 'Engineering', value: 'engineering' },
        { text: 'Design', value: 'design' },
        { text: 'Data Science', value: 'data_science' },
        { text: 'Product Management', value: 'product_management' },
        { text: 'Other', value: 'other' },
    ],
  },
]