import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import './SurveyCard.css';
import '../../styles/Text.css'

const SurveyCard = ({ choice, isSelected, onClick }) => {
    return (
        <Card
            className={isSelected ? 'survey-card-selected' : 'survey-card'}
            onClick={() => onClick(choice.value)}
        >
            <CardMedia
                component="img"
                height="160"
                image={choice.img}
                sx={{
                    width: '70%',
                    height: '70%',
                    marginTop: '10px'
                }}
            />
            <CardContent>
            <Typography className='normal-text-bold-mini'>{choice.text}</Typography>
            </CardContent>
        </Card>
    );
};

export default SurveyCard;
