export const MonthShort = [
    'Jan',
    'Feb',
    'Match',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
]
