import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ value }) => {

  const getColor = (value) => {
    if (value >= 0 && value <= 2) {
      return '#FF0000'; 
    } else if (value >= 2 && value < 4) {
      return '#FFA500'; 
    } else if (value >=4) {
      return '#4CAF50'; 
    }
    return '#4CAF50'; 
  };

  return (
    <div style={{ width: '45px', height: '45px' }}>
      <CircularProgressbar
        text={`${value}`}
        value={`${value}`}
        maxValue={5}
        styles={buildStyles({
          pathColor: getColor(value), 
          textColor: getColor(value), 
          trailColor: '#d6d6d6',
          backgroundColor: '#f3f3f3',
          textSize: '40px',
          strokeWidth: 15,
        })}
      />
    </div>
  );
};

export default CircularProgress;
