import React from 'react';
import { Button } from '@mui/material';
import './SumScoreCard.css';
import '../../styles/Text.css';
import '../../styles/Button.css';

const SumScoreCard = ({ score, position, round, date, description, onViewDetailsClick, showDetails }) => {
    return (
        <div className="sum-score-card">
            <span className="normal-text-light">Your score is</span>
            <div className="sum-score-card-score">
                <span className="sum-score-card-score-value title-text-thin">{score}</span>
                <span className="sum-score-card-score-small-value subtitle-text"> / 5</span>
            </div>
            <p className="sum-score-card-description normal-text">
                {description}
            </p>
            <div className="sum-score-card-details">
                <div className="sum-score-card-detail-item">
                    <span className="small-text-gray">Position</span>
                    <span className="sum-score-card-detail-value normal-text-bold-small">{position}</span>
                </div>
                <div className="sum-score-card-detail-item">
                    <span className="small-text-gray">Round</span>
                    <span className="sum-score-card-detail-value normal-text-bold-small">{round}</span>
                </div>
                <div className="sum-score-card-detail-item">
                    <span className="small-text-gray">Date</span>
                    <span className="sum-score-card-detail-value normal-text-bold-small">{date}</span>
                </div>
            </div>
            <div className="sum-score-card-feedback-button-container">
                <Button
                    className="main-button-long"
                    onClick={onViewDetailsClick}
                >
                    {showDetails ? 'Hide Detail Feedback' : 'View Detail Feedback'}
                </Button>
            </div>
        </div>
    );
};

export default SumScoreCard;
