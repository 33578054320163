import React from 'react';
import { Box, Typography } from '@mui/material';
import { TopBar } from '../../top_bar/TopBar';
import './CompleteInterview.css'
import '../../../styles/Text.css'
import Confetti from "react-confetti";


export default function CompleteInterview() {

    const PostInterviewPage =  (
    <>
        <TopBar/>
        <Box className="complete-interview">         
            <Confetti/>
          
            <Box className="complete-interview-image-box">      
                <img
                    src='/icons/feedback2.svg'
                    alt="feedback"
                    loading="lazy"
                />
            </Box>

            <Box className="large-text-container">      
                <Typography className='title-text-thin' style={{marginBottom: '15px'}}> Gathering Feedback ... </Typography>
            </Box>
        </Box>
    </>
    );

    return (
        PostInterviewPage
    )
}

