import { Box } from '@mui/material';  
import { ResponsiveRadar } from '@nivo/radar'
import ColorTheme from '../../styles/ColorTheme'
export const Radar = (props) => (
    <Box    
        height={400} 
        width="100%"
        padding={5}
        marginTop={6}
        justifyContent="center"
        alignItems="center"
        borderStyle="shadow"
        minWidth={200}
    >
        <ResponsiveRadar
            data={props.radarData}
            keys={["score (0-5)"]}
            indexBy="section"
            gridShape="linear"
            valueFormat=">-.2f"
            maxValue={5}
            margin={{ top:40, right: 35, bottom: 180, left: 45 }}
            borderColor={{ from: 'color' }}
            gridLabelOffset={20}
            dotSize={5}
            dotColor={{ theme: 'background' }}
            dotBorderWidth={0}
            linkStrokeWidth={50}
            colors={[ColorTheme.palette.primary.main]}
            blendMode="multiply"
            motionConfig="wobbly"
        />
    </Box>
)