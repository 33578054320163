import React, { useRef, useState, useEffect } from 'react';
import { Typography, Card, CardMedia, Box, Avatar } from '@mui/material';
import './MainScreen.css';

/*
/interview/?round=prod-sense
/interview/?round=hr-round
/interview/?round=customized/?job=${jobId}
*/

const MainScreen = (props) => {
  const videoRef = useRef(null);
  
  useEffect(() => {
    if (props.isVideoOn) {
      turnVideoOn();
    } else {
      turnVideoOff();
    }
  }, [props.isVideoOn]);

  const turnVideoOn = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: { aspectRatio: 16 / 9} })
        .then(stream => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch(error => {
          console.error('Error accessing the camera', error);
        });
    }
  };

  const turnVideoOff = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const videoTracks = videoRef.current.srcObject.getVideoTracks();
      if (videoTracks.length > 0) {
        videoTracks[0].stop();
      }
    }
  };

  return (
    <Box className="mainScreen">
      <Box className="mainScreen-content">
        {
          props.isVideoOn &&
          <Box className={`mainScreen-cand-videoon${props.isccOn ? "-ccon" : "-ccoff"}`}>
            <Card elevation={0}
                  className={`mainScreen${props.isccOn ? "-ccon" : "-ccoff"}-cardMedia mainScreen-indicator-${props.isCandSpeaking?"on" : "off"}`}
            >
              <CardMedia component="video" ref={videoRef} autoPlay playsInline/>
              <Box className="mainScreen-nameTagBox">
                <Typography className="mainScreen-nameTagText">{props.candidate}</Typography>
              </Box>
            </Card>
          </Box>
        }
        {
          !props.isVideoOn && 
          <Box className={`mainScreen-cand-videooff${props.isccOn ? "-ccon" : "-ccoff"}`}>
            <Box
                 className={`mainScreen${props.isccOn ? "-ccon" : "-ccoff"}-videoOffBox mainScreen-indicator-${props.isCandSpeaking?"on" : "off"}`}
            >
              {
                props.candidateImg?
                <Avatar src={props.candidateImg} className="mainScreen-cand-avatar"/> :
                <Avatar className="mainScreen-cand-avatar"> CAND </Avatar>
              }
              <Box className="mainScreen-nameTagBox">
                <Typography className="mainScreen-nameTagText">{props.candidate}</Typography>
              </Box>
            </Box>
          </Box>
        }
        {
          props.isccOn &&
          <Box className={`mainScreen-intv-ccon`}>
            <Box
                 className={`mainScreen-ccon-videoOffBox mainScreen-indicator-${props.isIntvSpeaking?"on" : "off"}`}
            >
              <Avatar className="mainScreen-intv-avatar"> INTV </Avatar>
              <Box className="mainScreen-nameTagBox">
                <Typography className="mainScreen-nameTagText">Interviewer</Typography>
              </Box>
            </Box>
          </Box>
        }
        {
          !props.isccOn &&
          <Box className={`mainScreen-intv-ccoff`}>
            <Box
                 className={`mainScreen-ccoff-videoOffBox mainScreen-indicator-${props.isIntvSpeaking?"on" : "off"}`}
            >
              <Avatar className="mainScreen-intv-avatar"> INTV </Avatar>
              <Box className="mainScreen-nameTagBox">
                <Typography className="mainScreen-nameTagText">Interviewer</Typography>
              </Box>
            </Box>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default MainScreen;
