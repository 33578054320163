import { Box } from "gestalt";  
import { ResponsiveRadar } from '@nivo/radar'

export const Radar = (props) => (
    <Box    
        height={300} 
        width="100%"
        padding={5}
        marginTop={15}
        animate={false}
        justifyContent="center"
        alignItems="center"
        borderStyle="shadow"
        minWidth={250}
    >
        <ResponsiveRadar
            data={props.radarData}
            keys={props.radarKey}
            indexBy="section"
            valueFormat=">-.2f"
            maxValue={5}
            gridShape="linear"
            margin={{ top: 25, right: 45, bottom: 30, left: 45 }}
            borderColor={{ from: 'color' }}
            gridLabelOffset={20}
            dotSize={5}
            dotColor={{ theme: 'background' }}
            dotBorderWidth={0}
            linkStrokeWidth={50}
            colors={{ scheme: 'pastel1' }}
            blendMode="multiply"
            motionConfig="wobbly"
        />
    </Box>
)