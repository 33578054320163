import ColorTheme from '../../styles/ColorTheme';
import '../../styles/Text.css';

const convertScore = (score) => {
    return score && score !== "n/a" ? parseFloat(score) : 0;
}

const getComputedStyle = (element, style) => {
    return window.getComputedStyle(element).getPropertyValue(style);
}

const getFontStylesFromCssClass = (className) => {
    const element = document.createElement('div');
    element.className = className;
    document.body.appendChild(element);
    const fontFamily = getComputedStyle(element, 'font-family');
    const fontWeight = getComputedStyle(element, 'font-weight');
    const fontSize = getComputedStyle(element, 'font-size');
    document.body.removeChild(element);
    return { fontFamily, fontWeight, fontSize };
}

const getRadarData = (feedback, round, evaluationCriteria) => {
    let data = [];
    const { fontFamily, fontWeight, fontSize } = getFontStylesFromCssClass('normal-text-bold');
    const options = {
        minHeight: 700,
        margin: { top: 80, right: 80, bottom: 80, left: 0 },
        maxValue: 5,
        curve: 'linearClosed',
        borderWidth: 2,
        gridLevels: 5,
        gridShape: 'circular',
        gridLabelOffset: 40,
        enableDots: true,
        dotSize: 8,
        dotColor: ColorTheme.palette.primary.main,
        dotBorderWidth: 0,
        colors: [ColorTheme.palette.primary.main],
        fillOpacity: 0.25,
        blendMode: 'multiply',
        animate: true,
        isInteractive: true,
        theme: {
            axis: {
                ticks: {
                    text: {
                        fontSize: parseInt(fontSize, 10), 
                        fontFamily: fontFamily,
                        fontWeight: fontWeight,
                        textAnchor: 'center',
                    }
                }
            }
        }
    }

    if (round === 'Quick Mock' && evaluationCriteria) {
        data = feedback.content
            .filter(item => evaluationCriteria.includes(item.section))
            .map(item => ({
                criteria: item.section,
                score: convertScore(item.score)
            }));
    } else {
        data = feedback.content.map(item => ({
            criteria: item.section,
            score: convertScore(item.score)
        }));
    }

    return { data, options };
}

export { getRadarData };
