import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import '../../styles/Text.css'
import './SurveyCheckBox.css';

const SurveyCheckBox = ({ choice, isSelected, onChange }) => {
    const optionBoxClass = `checkBox${isSelected ? '-selected' : ''}`;
    const textClassName = isSelected ? 'normal-text-bold-mini' : 'normal-text-mini';
    const handleCheckboxChange = (event) => {
        event.stopPropagation();
        onChange(choice.value);
    };

    return (
        <Box className={optionBoxClass} onClick={() => onChange(choice.value)}>
            <Checkbox
                checked={isSelected}
                color="primary"
                onChange={handleCheckboxChange} 
                sx={{
                    color: isSelected ? 'var(--color-primary-main)' : undefined,
                    '&.Mui-checked': {
                        color: 'var(--color-primary-main)',
                    }
                }}
            />
            <Typography className={textClassName} sx={{marginLeft: '5px'}}>
                {choice.text}
            </Typography>
        </Box>
    );
};


export default SurveyCheckBox;
