export const sessionExpireNotices = {
    "bg": "Извинете, изглежда не чух вашия отговор. Можете ли да го повторите?",
    "ca": "Disculpeu, sembla que no he sentit la vostra resposta. La podríeu repetir?",
    "zh": "不好意思，我似乎没有听到你的回复，你可以再重复一遍吗？",
    "zh-TW": "不好意思，我似乎沒有聽到你的回覆，你可以再重複一遍嗎？",
    "zh-HK": "不好意思，我似乎沒有聽到你的回覆，你可以再重複一遍嗎？",
    "cs": "Omlouvám se, zdá se, že jsem neslyšel vaši odpověď. Mohl byste ji zopakovat?",
    "da": "Beklager, jeg kunne ikke høre dit svar. Kunne du gentage det?",
    "nl": "Sorry, ik kon je antwoord niet horen. Kun je het herhalen?",
    "en": "Sorry, I can't hear you. Could you repeat what you said?",
    "et": "Vabandust, ma ei kuulnud teie vastust. Kas saaksite seda korrata?",
    "fi": "Anteeksi, en kuullut vastaustasi. Voisitko toistaa sen?",
    "nl-BE": "Sorry, ik kon je antwoord niet horen. Kun je het herhalen?",
    "fr": "Désolé, je n'ai pas entendu votre réponse. Pourriez-vous la répéter ?",
    "fr-CA": "Désolé, je n'ai pas entendu votre réponse. Pourriez-vous la répéter ?",
    "de": "Entschuldigung, ich konnte Ihre Antwort nicht hören. Könnten Sie sie wiederholen?",
    "de-CH": "Entschuldigung, ich konnte Ihre Antwort nicht hören. Könnten Sie sie wiederholen?",
    "el": "Συγγνώμη, δεν άκουσα την απάντησή σας. Θα μπορούσατε να την επαναλάβετε;",
    "hi": "माफ़ कीजिए, मुझे आपका जवाब सुनाई नहीं दिया। क्या आप इसे फिर से दोहरा सकते हैं?",
    "hu": "Elnézést, úgy tűnik, nem hallottam a válaszát. Megismételné?",
    "id": "Maaf, saya tidak bisa mendengar jawaban Anda. Bisakah Anda mengulanginya?",
    "it": "Mi scusi, non ho sentito la sua risposta. Potrebbe ripetere?",
    "ja": "すみません、返答が聞こえませんでした。もう一度言っていただけますか？",
    "ko": "죄송합니다, 대답을 듣지 못했습니다. 다시 말씀해 주시겠습니까?",
    "lv": "Atvainojiet, šķiet, ka nedzirdēju jūsu atbildi. Vai varat to atkārtot?",
    "lt": "Atsiprašau, negirdėjau jūsų atsakymo. Ar galite pakartoti?",
    "ms": "Maaf, saya tidak dapat mendengar jawapan anda. Bolehkah anda mengulanginya?",
    "multi": "Sorry, I can't hear you. Could you repeat what you said?",
    "no": "Beklager, jeg hørte ikke svaret ditt. Kan du gjenta det?",
    "pl": "Przepraszam, nie usłyszałem twojej odpowiedzi. Czy mógłbyś ją powtórzyć?",
    "pt": "Desculpe, não consegui ouvir sua resposta. Poderia repetir?",
    "pt-BR": "Desculpe, não consegui ouvir sua resposta. Poderia repetir?",
    "pt-PT": "Desculpe, não consegui ouvir sua resposta. Poderia repetir?",
    "ro": "Îmi cer scuze, nu v-am auzit răspunsul. Puteți repeta?",
    "ru": "Извините, я не услышал ваш ответ. Можете повторить?",
    "sk": "Ospravedlňujem sa, nepočul som vašu odpoveď. Môžete ju zopakovať?",
    "es": "Lo siento, no pude escuchar su respuesta. ¿Podría repetirlo?",
    "es-419": "Lo siento, no pude escuchar su respuesta. ¿Podría repetirlo?",
    "sv": "Ursäkta, jag hörde inte ditt svar. Kan du upprepa det?",
    "sv-SE": "Ursäkta, jag hörde inte ditt svar. Kan du upprepa det?",
    "th": "ขอโทษครับ/ค่ะ ฉันไม่ได้ยินคำตอบของคุณ คุณช่วยพูดอีกครั้งได้ไหม?",
    "tr": "Üzgünüm, cevabınızı duyamadım. Tekrar edebilir misiniz?",
    "uk": "Вибачте, я не почув вашу відповідь. Можете повторити?",
    "vi": "Xin lỗi, tôi không nghe thấy câu trả lời của bạn. Bạn có thể nhắc lại không?"
};
