import React, { useRef, useState, useEffect } from 'react';
import { Typography, Card, CardMedia, CircularProgress, Box, Avatar, Stack, Button, Tooltip, Table, TableRow, TableBody, TableCell, FormControlLabel, Checkbox } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone, faVideo, faVideoSlash, faMicrophoneSlash } from "@fortawesome/free-solid-svg-icons";
import './WaitingRoom.css';
import posthog from 'posthog-js';
import { Title, getText, languageMapping, WaitingContent, RoundInfo } from "./WaitingRoomConst"

/*
/interview/?round=prod-sense
/interview/?round=hr-round
/interview/?round=customized/?job=${jobId}
*/

const WaitingRoom = ({ isVideoOnGlobal, setVideoGlobal, isMicOnGlobal, setMicGlobal, setHasUserJoined, round, candidate, candidateImg, language, quickMockJobId, companyName, jobTitle, isSignedIn}) => {
  const videoRef = useRef(null);
  const [isVideoOn, setVideo] = useState(isVideoOnGlobal);
  const [isMicOn, setMic] = useState(isMicOnGlobal);
  const [languageDisplay, setLanguageDisplay] = useState("English");
  const [isJoinEnabled, setJoinEnabled] = useState(false); 
  const [privacyAccepted, setPrivacyAccepted] = useState(true);

  useEffect( () => {
    if (isVideoOn) {
        turnVideoOn();
    } else {
        turnVideoOff();
    }
  }, [isVideoOn]);
  
  useEffect(() => {
    setLanguageDisplay(languageMapping[language] || "English");
  }, [language]);

  useEffect(() => {
    if (round === 'Quick Mock') {
        if (quickMockJobId) {
            setJoinEnabled(true);
        } else {
            setJoinEnabled(false);
        }
    } else {
        setJoinEnabled(true);
    }
  }, [quickMockJobId, round]);


  const turnVideoOn = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: { aspectRatio: 16 / 9} })
        .then(stream => {
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
        })
        .catch(error => {
            console.error('Error accessing the camera', error);
        });
    }
  };

  const turnVideoOff = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const videoTracks = videoRef.current.srcObject.getVideoTracks();
      if (videoTracks.length > 0) {
        videoTracks[0].stop();
      }
    }
  };

  const micClick = () => {
    setMic(!isMicOn);
    setMicGlobal(!isMicOn);
  }

  const videoClick = () => {
    setVideo(!isVideoOn);
    setVideoGlobal(!isVideoOn);
  }

  const onJoin = () => {
    posthog.capture('interview_click_join_button', {});
    setHasUserJoined(true);
  }

  const handlePrivacyChange = (event) => {
    setPrivacyAccepted(event.target.checked);
  };

  const Icon =  (
    <Stack className="waitingRoom-icon-box" direction="horizontal">
        <Button
            variant="contained"
            size="large"
            className={!isMicOn ? "waitingRoom-icons-active" : "waitingRoom-icons"}
            onClick={micClick}
            >
            <Tooltip title={isMicOn ? "Mute" : "Unmute"}>
                <FontAwesomeIcon icon={!isMicOn ? faMicrophoneSlash : faMicrophone} />
            </Tooltip>
        </Button>

        <Button
            variant="contained"
            size="large"
            className={"waitingRoom-icons" + (!isVideoOn ? "-active" : "")}
            onClick={videoClick}
            >
            <Tooltip title={isVideoOn ? "Stop Video" : "Start Video"}>
                <FontAwesomeIcon icon={!isVideoOn ? faVideoSlash : faVideo} />
            </Tooltip>
        </Button>
    </Stack>
    );

  return (
    <Box className="waitingRoom">
        <Box className="waitingRoom-video-box">
            {
            isVideoOn &&
                <Card elevation={0} className={`waitingRoom-cardMedia`}>
                    <CardMedia component="video" ref={videoRef} autoPlay playsInline/>
                    <Box className="mainScreen-nameTagBox">
                        <Typography className="mainScreen-nameTagText">{candidate}</Typography>
                    </Box>
                    {Icon}
                </Card>
            }
            {
            !isVideoOn && 
                <Box className={`waitingRoom-video-off-background`}>
                    {
                        candidateImg?
                        <Avatar src={candidateImg} className="mainScreen-cand-avatar"/> :
                        <Avatar className="mainScreen-cand-avatar"> CAND </Avatar>
                    }
                    <Box className="mainScreen-nameTagBox">
                        <Typography className="mainScreen-nameTagText">{candidate}</Typography>
                    </Box>
                    {Icon}
                </Box>
            }
            
            
        </Box>

        <Stack direction="column" spacing={3} className="waitingRoom-infoCard-box">
            <Box sx={{ mb: 3 }}>
                <Typography className="title-text-thin">
                    {Title}
                </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
                <Typography className="normal-text">
                    {getText(jobTitle, companyName)}
                </Typography>
            </Box>
            <Table className="waitingRoom-table">
                <TableBody>
                    <TableRow>
                    <TableCell sx={{ borderBottom: "none"}} className="normal-text waitingRoom-tableCellOne">
                        Interview Round
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none"}} className="normal-text waitingRoom-tableCellTwo">
                        {RoundInfo(round)[0]}
                    </TableCell>
                    </TableRow >
                    <TableRow>
                    <TableCell className="normal-text waitingRoom-tableCellOne">Duration</TableCell>
                    <TableCell className="normal-text waitingRoom-tableCellTwo">
                        {RoundInfo(round)[1]}
                    </TableCell>
                    </TableRow>
                    <TableCell className="normal-text waitingRoom-tableCellOne">Language</TableCell>
                    <TableCell className="normal-text waitingRoom-tableCellTwo">
                        {languageDisplay}
                    </TableCell>
                </TableBody>
            </Table>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={privacyAccepted}
                        onChange={handlePrivacyChange}
                        color="primary"
                    />
                }
                label={
                    <Typography className="normal-text">
                        I agree to the{' '}
                        <a
                            href="https://www.mirwork.ai/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#1976d2', textDecoration: 'underline' }}
                        >
                            Privacy Policy
                        </a>
                    </Typography>
                }
            />
            {!isJoinEnabled && (
              <Box display="flex" alignItems="center" mb={0}>
                <CircularProgress size={20} style={{ marginRight: 8 }} />
                <Typography variant="body2">
                    {WaitingContent}
                </Typography>
              </Box>
            )}
            <Button variant="contained" onClick={onJoin} className="main-button-middle" disabled={!isJoinEnabled || !privacyAccepted || !isSignedIn}>
                Join Call
            </Button>
        </Stack>
    </Box>
  );
};

export default WaitingRoom;