import React, { useState } from 'react';
import './FlippableCard.css';
import '../../styles/Text.css';
import CircularProgress from './CircularProgress';
import posthog from 'posthog-js';

const FlippableCard = ({ score, title, description, improvement }) => {
    const [isFlipped, setIsFlipped] = useState(false);
    
    const convertScore = (score) => {
        if (score === "n/a" || score === null || score === undefined) {
            return 0;
        }
        const numScore = parseFloat(score);
        return isNaN(numScore) ? 0 : numScore;
    };

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
      posthog.capture('flippable_card_click', {
        card_title: title,
        card_description: description,
        card_improvement: improvement
      })
    };

    return (
        <div
            className={`feedback-card-container ${isFlipped ? 'flipped' : ''}`}
            onClick={handleFlip}
        >
            <div className="feedback-flippableCard normal-text">
                <div className="feedback-card-front">
                    <div className="feedback-circular-progress-container normal-text-bold-small">
                        <CircularProgress value={convertScore(score)} />
                    </div>
                    <h2 className="feedback-card-title normal-text-bold-small">{title}</h2>
                    <p className="feedback-card-description normal-text-small">{description}</p>
                </div>
                <div className="feedback-card-back">
                    <h2 className="feedback-card-back-title normal-text-bold-small">HOW TO IMPROVE</h2>
                    <p className="feedback-card-back-description normal-text-small">{improvement}</p>
                </div>
                <div className="feedback-indicator-container">
                    <div className={`feedback-indicator ${isFlipped ? '' : 'active'}`}></div>
                    <div className={`feedback-indicator ${isFlipped ? 'active' : ''}`}></div>
                </div>
            </div>
        </div>
    );
};

export default FlippableCard;
