import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Button} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './RecordCard.css';
import '../../styles/Text.css'

const RecordCard = ({ interviewNumber }) => {
    return (
            <Box class="centered-box">
                <Box 
                    className="dashboard-image-box"
                    marginTop='5px'
                >
                    <img
                        src="/icons/feedback1.svg"
                        alt="feedback"
                        loading="lazy"
                        sx={{
                            width: '70%',
                            height: '70%',
                            marginTop: '10px'
                        }}
                    />
                </Box>
                <Box className="dashboard-horizon-container">
                    <Box className="dashboard-vertical-container" >
                        <Box>
                            <Typography className='normal-text-mini'>Interviews</Typography>
                        </Box>
                        <Box>
                            <Typography className='title-text-thin'>{interviewNumber}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Button className="main-button-white-start-interview" href="/interview" endIcon={<SendIcon />}>
                            New Interview
                        </Button>
                    </Box>
                </Box>
            </Box>
    );
};

export default RecordCard;
