import { createTheme } from '@mui/material/styles';

// Use var(--color-primary/secondary-light/main/dark) in css or sx styles
const ColorTheme = createTheme({
  palette: {
    primary: {
      fade: '#e9f2ff',  
      semilight: 'b2cff5',
      light: '#8FB4E6',      
      main: '#5483C3',
      semidark: '#3370c5',
      dark: '#2d5da1',
    },
    secondary: {
      fade: '#fffaee',
      light: '#F6C445',
      main: '#E45F2B',
    },
  },
});

export default ColorTheme;