import UserSurvey from './pages/user_survey/UserSurvey'
import Interview from './pages/interview/Interview'
import History from './pages/history/History'
import Feedback from './pages/feedback/Feedback'
import Dashboard from './pages/dashboard/Dashboard'
// import Pricing from './pages/pricing/Pricing'

import { BrowserRouter, Routes, Route, Outlet, Navigate } from 'react-router-dom';

export default function Router() {
    const Layout = () => {
        return (
            <>
            <div className="content"><Outlet /></div>
            </>
        )
    }

    return (
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to="/home" replace />} />
                <Route path="/interview" element={<Interview />} />
                <Route path="/home" element={<Dashboard />} />
                <Route path="/survey" element={<UserSurvey />} />
                <Route path="/history" element={<History />} />
                <Route path="/feedback" element={<Feedback />} />
                {/* <Route path="/pricing" element={<Pricing />} /> */}
            </Route>
            </Routes>
        </BrowserRouter>
    )
}