const roundType = ["hr-round", "prod-sense", "analytical-execution", "leadership"]
const hrRoundSection = [
    "Experience Fit", 
    "Resume Accuracy", 
    "Communication Skills", 
    "Leadership and Team Management Skills", 
    "Problem Solving Skills", 
    "Overall Performance"
]
const productSenseSection = [
    "Clarifying Questions",  
    "Business Acumen", 
    "Product Vision", 
    "User Centricity", 
    "Passion and Creativity", 
    "Trade-offs Discussion", 
    "Communication", 
    "Collaboration"
]
const analyticalSection = [
    "Ability to Isolate Variables", 
    "Data Literacy", 
    "Logical Thinking", 
    "Intuition for Problem Solving", 
    "Communication", 
    "Collaboration"
]
const leadershipSection = [
    "Clarifying Questions", 
    "Business Acumen", 
    "Logical Thinking", 
    "Intuition for Problem Solving", 
    "Communication", 
    "Collaboration"
]
const radarKeys = new Map([
    ['hr-round', hrRoundSection],
    ['prod-sense', productSenseSection],
    ['analytical-execution', analyticalSection],
    ['leadership', leadershipSection]
]);
const getRadarKeys = (round, evaluationCriteria) => {
    let sections;
    if (round === 'Quick Mock') {
        sections = evaluationCriteria.map(criteria => ({
            title: criteria
        }));
    } else {
        const sectionList = radarKeys.get(round) || [];
        sections = sectionList.map(title => ({
            title: title
        }));
    }
    return sections;
};

export { getRadarKeys, roundType, radarKeys };
