import axios from "axios";

export async function GetUser( isAuthenticated, user )  {

    let userData = {
        email: "",
        firstName: "",
        lastName: "",
        avatar: "",
        id: null,
    };

    if (isAuthenticated) {

        userData = {
            email: user.primaryEmailAddress.emailAddress,
            firstName: "",
            lastName: "",
            avatar: user.imageUrl,
            id: null,
        };
        const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/auth/signup";
        try {
            let response = await axios.post(
                apiUrl, { email: user.primaryEmailAddress.emailAddress}
            )
            if (response.data.registered){
                userData.id = response.data.data.id;
                userData.firstName = response.data.data.first_name;
                userData.lastName = response.data.data.last_name;
            }
            return userData;
        } catch(error) {
            console.error("Error saving message:", error);
        }
    } 

    return userData;
}