import { createTheme } from '@mui/material/styles';

const StackMargin = createTheme({
  components: {
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
});

export default StackMargin;